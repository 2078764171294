








































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CostumerOwerview2 extends Vue {
  public projects = ['FLAIR 113 - ZET-Bauträgergesellschaft mbH', 'Doppelhaus Behringen 116 - Trend - ZET-Bauträgergesellschaft mbH', 'Stadthaus Flair 152 RE - Trend - EVIRA Hausprojekt GmbH']
  public seletedProject = 'FLAIR 113 - ZET-Bauträgergesellschaft mbH'
  public projectDetails = {
    'number': 'I2020_NR_-123BWW',
    'location': '14979 Großbeeren',
    'type': 'Doppelhaus Behringen 116 - Trend',
    'intervall': {'start': '01.01.2020', 'end': '12.12.2023'},
    'status': 'Schallschutz - unterlagen fehlen',
    'assignments': [
      {'name': 'Static', 'isAssignment': true},
      {'name': 'EnEV', 'isAssignment': false},
      {'name': 'Schallschutz', 'isAssignment': true},
    ],
  }

  public getGenerellInfos(): any {
    return [
      {name: 'ProjektNr.:', value: this.projectDetails.number},
      {name: 'Ort:', value: this.projectDetails.location},
      {name: 'Haustyp:', value: this.projectDetails.type},
      {name: 'Eingangsdatum:', value: this.projectDetails.intervall.start},
      {name: 'Enddatum:', value: this.projectDetails.intervall.end},
      {name: 'Bearbeitungsstand', value: this.projectDetails.status},
    ]
  }
}
